@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
* {
  color: white;
  font-family:'Nunito', sans-serif;
}

body {
  margin: 0;
  font-family:'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(266deg, #4d9ed1, #40c9c5, #4974a9);
  background-size: 800% 800%;


  -webkit-animation: shower-song-background 40s ease infinite;
  animation: shower-song-background 40s ease infinite;
}

@-webkit-keyframes shower-song-background {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
@keyframes shower-song-background {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #4da1cf;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.start-page__background { 
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%,  rgba(0,68,89,0.5) 50%, rgba(255,255,255,0) 100%);
}

.result-page__background { 
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%,rgba(77, 176, 202,0.5) 30%, rgba(255,255,255,0) 100%);
}

.start-page__logo {
  width: 45%
}

.litre-count {
  font-size: 2em;
}


.start-page__title {
  font-size: 2.5em;
}

.start-page__quote {
  font-size: 1.1em;
  padding-top: 10px;
  padding: 10px;
}

.start-page__reference {
  font-size: 0.9em;
}

.start-page__tagline {
  font-size: 1.6em;
  padding: 20px;
}

.start-page__connect-button {
  background-color: #1eb953;
  padding: 10px;
  max-width: 250px;
  margin: 0 auto;
  margin-top: 20px;
  max-height: 44px;
  border-radius: 22px;
  width: 100%;
  border: none;
  box-shadow: 0 4px 12px -6px black;
}

.start-page__continue-without-button {
  /* background-color: rgba(163,163,163,0.1); */
  background-color: transparent;
  padding: 10px;
  max-width: 250px;
  margin: 0 auto;
  margin-bottom: 10px;
  height: 44px;
  border-radius: 22px;
  width: 100%;
  border: none;
  font-size: 0.9em;
}

.start-page__or {
  font-size: 0.8em;
  padding-top: 10px;
}

@media (max-width: 199px) {
  *{ font-size: 88%;}
}

@media (min-width: 200px) and  (max-width: 249px) {
  *{ font-size: 91%;  }
}

@media (min-width: 250px) and  (max-width: 299px) {
  *{ font-size: 94%;}
}

@media (min-width: 300px) and  (max-width: 349px) {
  *{ font-size: 97%;}
}

@media (min-width: 350px) {
  *{ font-size: 100%; }
}

.bottom-content{
  position: relative;
top: 75%;
-webkit-transform: translateY(-75%);
        transform: translateY(-75%);
}



.button-icon {
  margin-right: 4px;
  margin-bottom: 2px;
}

.recap__time {
  min-height: 150px;
  margin-top: 10px;
}

.recap-page__logo{
  width: 20%;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  bottom: 3px;

}

.recap-page__title {
  display: inline-block;
  font-size: 2em;
}


.error-message {
  font-size: 0.5em;
  padding: 20px;
}

.carousel-status{
  display: none;
}

.thumbs-wrapper {
  display: none;
}

.carousel .slide{
  background: transparent !important;
  margin: auto !important;
}

.slide {
  margin: auto;
}
.spotify-connect__button {
    margin: 50px auto;
    display: block !important;
}


.custom-control-label {
padding-right: 10px;
}

.form-check-inline {
    padding-top: 10px;
    padding-bottom: 20px
}

.rc-slider-dot {
    display: none;
}

.time-slider > .rc-slider > .rc-slider-step {
    background: rgb(3,116,0);
    background: linear-gradient(90deg, #29c59e 0%, #29c59e 25%, #4da0ce 100%);
    height: 16px;
    border: 2px solid white;
    border-radius: 8px
}

.rc-slider-step {
    background: #29c59e;
    height: 16px;
    border: 2px solid white;
    border-radius: 8px
}

.rc-slider-mark-text{
    color: white;
    padding-top: 10px
}

.rc-slider-rail {
    display: none !important;
}

.rc-slider-track{ 
    display: none !important;
}

.rc-slider-handle {
    height: 25px;
    width: 25px;
    background: #d4fff5;
    border: 2px solid white;
}


.central-content{
    position: relative;
    top: 47.5%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.preferences__question {
    font-size: 1.3em;
    text-align: left;
    padding-bottom: 20px;
}

.preferences__continue-button {
    position: absolute;
    left: 50%;
    width: 100%;
    margin-left: -125px;
    bottom: 10%;
    background-color: transparent;
    max-width: 250px;
    padding: 10px;
    border: none;
    border-radius: 100px;
    box-shadow: 0 4px 12px -6px black;
}

.song__enjoy {
    font-size: 1.3em;
    color: #4da1cf;
    margin-top: 40px;
    position: absolute;
    left: 50%;
    width: 100%;
    margin-left: -50vw;
    bottom: 2%;
    max-width: 100vw;
  }

.preferences__question--options{
    margin-top: 10px;
}

.more-button {
    margin-left: 2px;
    font-size: 1.5em;
}

.preferences__find-button {
    position: absolute;
    left: 50%;
    width: 100%;
    margin-left: -125px;
    bottom: 5%;
    background-color: transparent;
    max-width: 250px;
    padding: 10px;
    border: none;
    border-radius: 100px;
    box-shadow: 0 4px 12px -6px black;
}

.preferences__continue-button--inline{
    width: 100%;
    background-color: transparent;
    max-width: 250px;
    padding: 10px;;
    margin-top: 20px;
    border: none;
    border-radius: 100px;
    box-shadow: 0 4px 12px -6px black;
}

.preferences{
    position: relative;
    padding: 10px;
}

.song-result{
    position: relative;
}

.time-container{
    min-height: 150px
}

.preferences__back-button {
    position: absolute;
    left: 50%;
    width: 100%;
    margin-left: -25px;
    top: 0%;
    background-color: transparent;
    max-width: 50px;
    padding: 10px;
    border: none;
    border-radius: 100px;
    font-size: 1.4em;
}

.preferences__slider-label {
    font-size: 0.8em;
    height: 50px;
}
.preferences__slider-label--left {
    float: left;
}

.preferences__slider-label--right {
    float: right;
}

.preferences__slider{
    height: 60px;  
}

.rc-slider {
    padding-top: 25px;
}


.time-slider > .rc-slider {
    padding-top: 0px;
}

.radio-option {
    font-size: 0.8em !important;
    margin-bottom: 20px;
    background-color: transparent !important;
    border: 2px solid white !important;
    border-radius: 100px;
    width: 50% !important;
}

.radio-option--active {
    font-size: 0.8em !important;
    margin-bottom: 20px;
    background-color: #29c59e !important;
}

.radio-options {
   width: 100%;
}

.song__message {
   text-align: left;
   font-size: 1.3em;
}

.song__image {
    width: 80%;
    margin: 5% 10%;
    box-shadow: 0 4px 12px -6px black;
    max-width: 350px;
}

.error-button {
    margin: 10px;
    background-color: transparent;
    max-width: 250px;
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: 100px;
    box-shadow: 0 4px 12px -6px black;
}

.song__name {
    font-size: 1.5em;
    max-height: 1.2em;
    overflow: hidden;
    max-height: 1.5em;
    overflow: hidden;
}


.song__artist {
    font-size: 1.2em;
    margin-bottom: 10px;
    max-height: 1.2em;
    overflow: hidden;
}

.song__listen-button {
    border-radius: 100px;
    width: 100%;
    max-width: 250px;
    border: none;
    margin-top: 10px;
    font-size: 1em;
    padding: 10px;
    box-shadow: 0 4px 12px -6px black;
}

.song__listen-button--spotify {
    background-color: #1eb953;
    margin-top: 20px;
}

.song__listen-button--youtube {
    background-color: #f70302;
}

.welcome-page__logo {
    width: 70%
}

.welcome-page__message {
    font-size: 1.3em
}

.welcome-page__explain {
    padding: 10px;
    padding-bottom: 30px;
    
}

.welcome-page__alt-option{
    font-size: 0.7em;
    padding-bottom: 100px;
}

.link {
    text-decoration: underline;
}

.genre-grid {
    width: 100% !important;
    margin-bottom: 20px;
    border-radius: 8px !important;
}

.genre-button {
    font-size: 1em !important;
    padding: 6px 0px !important;
    background-color: transparent !important;
    border: 2px solid white !important;
    width: 25% !important;
}

.genre-button--active {
    background-color: #29c59e !important;
}

.share__container {
    padding: 20px 0px;
    padding-top: 30px;
    white-space: nowrap;
    max-width: 100%;
    overflow: scroll;
}

.share__button {
    margin: 0 2px
}

.time-slider {
    min-height: 75px !important;
  }
  
