@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
  color: white;
  font-family:'Nunito', sans-serif;
}

body {
  margin: 0;
  font-family:'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(266deg, #4d9ed1, #40c9c5, #4974a9);
  background-size: 800% 800%;


  -webkit-animation: shower-song-background 40s ease infinite;
  -moz-animation: shower-song-background 40s ease infinite;
  animation: shower-song-background 40s ease infinite;
}

@-webkit-keyframes shower-song-background {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
@-moz-keyframes shower-song-background {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
@keyframes shower-song-background {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
