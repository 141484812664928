.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #4da1cf;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.start-page__background { 
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%,  rgba(0,68,89,0.5) 50%, rgba(255,255,255,0) 100%);
}

.result-page__background { 
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%,rgba(77, 176, 202,0.5) 30%, rgba(255,255,255,0) 100%);
}

.start-page__logo {
  width: 45%
}

.litre-count {
  font-size: 2em;
}


.start-page__title {
  font-size: 2.5em;
}

.start-page__quote {
  font-size: 1.1em;
  padding-top: 10px;
  padding: 10px;
}

.start-page__reference {
  font-size: 0.9em;
}

.start-page__tagline {
  font-size: 1.6em;
  padding: 20px;
}

.start-page__connect-button {
  background-color: #1eb953;
  padding: 10px;
  max-width: 250px;
  margin: 0 auto;
  margin-top: 20px;
  max-height: 44px;
  border-radius: 22px;
  width: 100%;
  border: none;
  box-shadow: 0 4px 12px -6px black;
}

.start-page__continue-without-button {
  /* background-color: rgba(163,163,163,0.1); */
  background-color: transparent;
  padding: 10px;
  max-width: 250px;
  margin: 0 auto;
  margin-bottom: 10px;
  height: 44px;
  border-radius: 22px;
  width: 100%;
  border: none;
  font-size: 0.9em;
}

.start-page__or {
  font-size: 0.8em;
  padding-top: 10px;
}

@media (max-width: 199px) {
  *{ font-size: 88%;}
}

@media (min-width: 200px) and  (max-width: 249px) {
  *{ font-size: 91%;  }
}

@media (min-width: 250px) and  (max-width: 299px) {
  *{ font-size: 94%;}
}

@media (min-width: 300px) and  (max-width: 349px) {
  *{ font-size: 97%;}
}

@media (min-width: 350px) {
  *{ font-size: 100%; }
}

.bottom-content{
  position: relative;
top: 75%;
transform: translateY(-75%);
}



.button-icon {
  margin-right: 4px;
  margin-bottom: 2px;
}

.recap__time {
  min-height: 150px;
  margin-top: 10px;
}

.recap-page__logo{
  width: 20%;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  bottom: 3px;

}

.recap-page__title {
  display: inline-block;
  font-size: 2em;
}


.error-message {
  font-size: 0.5em;
  padding: 20px;
}

.carousel-status{
  display: none;
}

.thumbs-wrapper {
  display: none;
}

.carousel .slide{
  background: transparent !important;
  margin: auto !important;
}

.slide {
  margin: auto;
}