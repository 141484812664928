

.custom-control-label {
padding-right: 10px;
}

.form-check-inline {
    padding-top: 10px;
    padding-bottom: 20px
}

.rc-slider-dot {
    display: none;
}

.time-slider > .rc-slider > .rc-slider-step {
    background: rgb(3,116,0);
    background: linear-gradient(90deg, #29c59e 0%, #29c59e 25%, #4da0ce 100%);
    height: 16px;
    border: 2px solid white;
    border-radius: 8px
}

.rc-slider-step {
    background: #29c59e;
    height: 16px;
    border: 2px solid white;
    border-radius: 8px
}

.rc-slider-mark-text{
    color: white;
    padding-top: 10px
}

.rc-slider-rail {
    display: none !important;
}

.rc-slider-track{ 
    display: none !important;
}

.rc-slider-handle {
    height: 25px;
    width: 25px;
    background: #d4fff5;
    border: 2px solid white;
}


.central-content{
    position: relative;
    top: 47.5%;
    transform: translateY(-50%);
}

.preferences__question {
    font-size: 1.3em;
    text-align: left;
    padding-bottom: 20px;
}

.preferences__continue-button {
    position: absolute;
    left: 50%;
    width: 100%;
    margin-left: -125px;
    bottom: 10%;
    background-color: transparent;
    max-width: 250px;
    padding: 10px;
    border: none;
    border-radius: 100px;
    box-shadow: 0 4px 12px -6px black;
}

.song__enjoy {
    font-size: 1.3em;
    color: #4da1cf;
    margin-top: 40px;
    position: absolute;
    left: 50%;
    width: 100%;
    margin-left: -50vw;
    bottom: 2%;
    max-width: 100vw;
  }

.preferences__question--options{
    margin-top: 10px;
}

.more-button {
    margin-left: 2px;
    font-size: 1.5em;
}

.preferences__find-button {
    position: absolute;
    left: 50%;
    width: 100%;
    margin-left: -125px;
    bottom: 5%;
    background-color: transparent;
    max-width: 250px;
    padding: 10px;
    border: none;
    border-radius: 100px;
    box-shadow: 0 4px 12px -6px black;
}

.preferences__continue-button--inline{
    width: 100%;
    background-color: transparent;
    max-width: 250px;
    padding: 10px;;
    margin-top: 20px;
    border: none;
    border-radius: 100px;
    box-shadow: 0 4px 12px -6px black;
}

.preferences{
    position: relative;
    padding: 10px;
}

.song-result{
    position: relative;
}

.time-container{
    min-height: 150px
}

.preferences__back-button {
    position: absolute;
    left: 50%;
    width: 100%;
    margin-left: -25px;
    top: 0%;
    background-color: transparent;
    max-width: 50px;
    padding: 10px;
    border: none;
    border-radius: 100px;
    font-size: 1.4em;
}

.preferences__slider-label {
    font-size: 0.8em;
    height: 50px;
}
.preferences__slider-label--left {
    float: left;
}

.preferences__slider-label--right {
    float: right;
}

.preferences__slider{
    height: 60px;  
}

.rc-slider {
    padding-top: 25px;
}


.time-slider > .rc-slider {
    padding-top: 0px;
}

.radio-option {
    font-size: 0.8em !important;
    margin-bottom: 20px;
    background-color: transparent !important;
    border: 2px solid white !important;
    border-radius: 100px;
    width: 50% !important;
}

.radio-option--active {
    font-size: 0.8em !important;
    margin-bottom: 20px;
    background-color: #29c59e !important;
}

.radio-options {
   width: 100%;
}

.song__message {
   text-align: left;
   font-size: 1.3em;
}

.song__image {
    width: 80%;
    margin: 5% 10%;
    box-shadow: 0 4px 12px -6px black;
    max-width: 350px;
}

.error-button {
    margin: 10px;
    background-color: transparent;
    max-width: 250px;
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: 100px;
    box-shadow: 0 4px 12px -6px black;
}

.song__name {
    font-size: 1.5em;
    max-height: 1.2em;
    overflow: hidden;
    max-height: 1.5em;
    overflow: hidden;
}


.song__artist {
    font-size: 1.2em;
    margin-bottom: 10px;
    max-height: 1.2em;
    overflow: hidden;
}

.song__listen-button {
    border-radius: 100px;
    width: 100%;
    max-width: 250px;
    border: none;
    margin-top: 10px;
    font-size: 1em;
    padding: 10px;
    box-shadow: 0 4px 12px -6px black;
}

.song__listen-button--spotify {
    background-color: #1eb953;
    margin-top: 20px;
}

.song__listen-button--youtube {
    background-color: #f70302;
}

.welcome-page__logo {
    width: 70%
}

.welcome-page__message {
    font-size: 1.3em
}

.welcome-page__explain {
    padding: 10px;
    padding-bottom: 30px;
    
}

.welcome-page__alt-option{
    font-size: 0.7em;
    padding-bottom: 100px;
}

.link {
    text-decoration: underline;
}

.genre-grid {
    width: 100% !important;
    margin-bottom: 20px;
    border-radius: 8px !important;
}

.genre-button {
    font-size: 1em !important;
    padding: 6px 0px !important;
    background-color: transparent !important;
    border: 2px solid white !important;
    width: 25% !important;
}

.genre-button--active {
    background-color: #29c59e !important;
}

.share__container {
    padding: 20px 0px;
    padding-top: 30px;
    white-space: nowrap;
    max-width: 100%;
    overflow: scroll;
}

.share__button {
    margin: 0 2px
}

.time-slider {
    min-height: 75px !important;
  }
  